<template>
  <vs-card>
    <div class="link-card">
      <p v-html="item.icon" class="mr-3"> </p>

      <div>
        <h5 class="text-base font-medium">
          {{ item.title }}
        </h5>
        <p class="text-sm font-normal">{{ item.sub }}</p>
      </div>
    </div>
  </vs-card>
</template>

<script>
export default {
  props: ["item"]
};
</script>